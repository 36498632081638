import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './TwoFactorAuthentication.scss';

const TwoFactorAuthVerify = (props) => {

    const { show, handleClose, handleSuccess, setAuthOTP, authOTP } = props;

    const [error, setError] = useState(null);

    const hanndleSubmit = (e) => {
        if (authOTP === null || String(authOTP).trim() === '') {
            setError('Verification code is required');
            return false;
        } 

        handleSuccess(e)
        handleClose()
    }

    return (

        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gst_gauth_verifycode_module modal"
            >

                <Modal.Header closeButton className="gst_gauth_verifycode_header">
                    <h4 className="modal-title">2FA Verification Code</h4>
                </Modal.Header>

                <Modal.Body className='pt-2 pb-2 px-3'>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="gst_gauth_verifycode_textfs">
                                Enter the verification code generated by your authenticator app
                            </p>
                            <div className="form-group gst_gauth_verifycode_inputgrp">
                                <input
                                    type="text"
                                    className="form-control gst_gauth_verifycode_inputbox"
                                    placeholder="Enter Code"
                                    maxLength={15}
                                    minLength={1}
                                    value={authOTP}
                                    onChange={(e) =>  {
                                        setError('');
                                        setAuthOTP(e.target.value.trim());                                        
                                    }}
                                />                               
                                {
                                    error &&
                                    <span className='error_message'>{error}</span>
                                }
                            </div>
                        </div>

                        <div className="col-xl-12 text-center pt-3">
                            <button className="btn btn-default gst_factorauth_scanbar_verifybtn mr-2 mb-1" onClick={(e) => {
                                hanndleSubmit(e)
                            }}>
                                Verify
                            </button>
                            <button className="btn btn-default gst_factorauth_scanbar_verifybtn" onClick={() => handleClose(true)}>
                                Back
                            </button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default TwoFactorAuthVerify