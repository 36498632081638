import React, { useEffect, useState } from 'react'
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import { updateFilingHistoryOnServer, verifyEFilingCredsFromConnector } from '../../api/localConnectorAPI';
import { verfiyEfilingCrediationalOnServer } from '../../api/ReturnRegister';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import ITConnector from '../../components/kdkConnector/ITConnector';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import SyncData from '../../components/SyncData';
import { deepClone, getCurrentFinancialYearByQuarter } from '../../utils/UtilityFunctions';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { gocallApi } from '../../api/issueCertificateAPI';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import ShowAlert from '../../components/ShowAlert';
//import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//import { useLocation } from 'react-router-dom';


function DeductorSyncClient(props) {

    const { syncDeductor, deductorId, financial_year, lastSyncStatus, tracesSyncStart, setSyncStart, latestSyncStatus, setFirstTime } = props;
    const headerData = useSelector(selectHeaderData);

    const [error, setError] = useState(false);
    const [error1, setError1] = useState(false);
    const [, setSuccess] = useState(false);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [port, setPort] = useState();
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [showValidationFailPopup, setShowValidationFailPopup] = useState(false);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    //const history = useHistory();
    //const location = useLocation();
    //const pathName = location.pathname;

    const verfiyCredApi = async (port, syncdata, tracesSyncStart, setSyncStart) => {

        if (!port) {
            return false;
        }
        const obj = deepClone(syncdata);
        if (!obj.itdportal_password) {
            setShowValidationFailPopup(true)
            return;
        }
        const verifyLoginData = {
            'itdportal_username': obj?.itdportal_username ?? '',
            'itdportal_password': obj?.itdportal_password ?? ''
        }
        setLoading(true);

        try {
            const loginResult = await verifyEFilingCredsFromConnector(port, verifyLoginData);

            if (showValidationFailPopup) updateCredentialsOnserver(syncdata?.itdportal_username ?? "", syncdata?.itdportal_password ?? "");

            const verigyEfilingCreditional = {
                "deductor_id": obj.deductor_id ?? "",
                "captcha_ref": loginResult.data.captcha_ref ?? "",
            }
            try {

                const loginVerified = await verfiyEfilingCrediationalOnServer(verigyEfilingCreditional);

                if (loginVerified.data.status === 1) {
                    let extraHeader = {};
                    const LoginVerifiedResult = loginVerified.data
                    const responseHeader = loginVerified.headers ?? null;

                    const connectorRequest = {
                        "data": LoginVerifiedResult.data
                    };

                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                    await updateFilingHistoryOnServer(port, connectorRequest, extraHeader);
                    if (tracesSyncStart) setCheckTracesCredentials(true);
                }

            } catch (err) {
                console.error('2 error: ', err);
                if (err.message[0] === 'itefil.invalid_password' || err.code === 424) {
                    setShowValidationFailPopup(true);
                    const errmsg = CONNECTOR_ERRORS_MAPPING?.[err.message?.[0]] ?? err.message?.[0];
                    setError1(errmsg);
                } else {
                    const errmsg = CONNECTOR_ERRORS_MAPPING[err.message?.[0]] ?? err.message?.[0];
                    setError1(errmsg);
                }
                if (setSyncStart) setSyncStart(false);
            }
        } catch (err) {
            console.error('2 error: ', err);
            if (err.message[0] === 'itefil.invalid_password' || err.code === 424) {
                setShowValidationFailPopup(true);
                const errmsg = CONNECTOR_ERRORS_MAPPING?.[err.message?.[0]] ?? err.message?.[0];
                setError1(errmsg);
            } else {
                const errmsg = CONNECTOR_ERRORS_MAPPING[err.message?.[0]] ?? err.message?.[0]
                setError1(errmsg);
            }
            if (setSyncStart) setSyncStart(false);


        } finally {
            setCheckItCredentials(false);
            setLoading(false);
        }

    }

    const updateCredentialsOnserver = async (itdportal_username, itdportal_password) => {

        setLoading1(true);
        const payload = {
            deductor_id: deductorId ?? "",
            deductor_name: headerData?.deductor_name ?? "",
            efilling_password: itdportal_password ?? "",
            deductor_status: 1,
            deductor_credentials: [
                {
                    credential_type: "EFILING",
                    username: itdportal_username,
                    password: itdportal_password,                    
                  }
            ]

        }

        const url = "/api/v1/deductor"

        try {
            await gocallApi("put", url, payload)
            setShowValidationFailPopup(false);

        } catch (err) {
            console.error(err);

        } finally {
            setLoading1(false);
        }
    }

    useEffect(() => {
        if (port && checkItCredentials) {
            verfiyCredApi(port, syncDeductor, tracesSyncStart, setSyncStart);
        }

        // eslint-disable-next-line
    }, [port, checkItCredentials, syncDeductor, tracesSyncStart, setSyncStart]);

    useEffect(() => {
        if (syncDeductor) {
            setShowConnnectorCall(true);
        }

    }, [syncDeductor])

    return (
        <>
            {error &&
                <ShowAlert
                    error={error}
                    onClose={() => setError(null)}
                />
            }
            {loading ? <LoadingIndicator /> : null}
            {loading1 ? <LoadingIndicator /> : null}

            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => {
                        setShowConnnectorCall(false);
                        if(setFirstTime) {
                            setFirstTime(false);
                        }
                    }}
                    setPort={setPort}
                    handleProceed={() => setCheckItCredentials(true)}
                />
                : null}



            {showValidationFailPopup &&
                <ITConnector
                    syncData={syncDeductor}
                    show={showValidationFailPopup}
                    handleClose={() => {
                        setShowValidationFailPopup(false);
                        if (setSyncStart) setSyncStart(false);
                    }}
                    verfiyCredentialApi={(syncdata) => {
                        verfiyCredApi(port, syncdata, tracesSyncStart, setSyncStart)
                    }}
                    loading={loading}
                    error1={error1}
                    setError1={setError1}
                    latestSyncStatus={latestSyncStatus}

                />
            }

            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={() => {
                        setShowConnnectorCall(false);
                        setCheckTracesCredentials(false);
                    }}
                    tan={syncDeductor?.itdportal_username ?? ""}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                    latestSyncStatus={latestSyncStatus}
                />
                : null
            }

            {captchaRef && port
                ? (
                    <SyncData
                        deductorId={deductorId}
                        returnItem={{
                            'financial_year': financial_year ?? getCurrentFinancialYearByQuarter(),
                        }}
                        port={port}
                        captchaRef={captchaRef}
                        setSuccess={setSuccess}
                        setError={setError}
                        setShowSyncVerify={() => {
                            // setShowSyncVerifyStatus(tan);
                            setShowConnnectorCall(false);
                            setCheckTracesCredentials(false);
                            setCaptchaRef(null);
                        }}
                        handleProceed={lastSyncStatus}
                        setSyncStart={setSyncStart}
                        setLoading={setLoading}
                    />
                )
                : null}
        </>
    )
}

export default DeductorSyncClient