import React, { useEffect, useState } from 'react';
import './TwoFactorAuthentication.scss';
import authIcon from '../../images/factorauthimg.png';
import delIcon from '../../images/del-icon.png';
import EnableTwoFactorAuthentication from './EnableTwoFactorAuthentication';
import { check2FAAuthStatus, disable2FAuthentication } from '../../api/authenticationAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '../../app/constants';


const TwoFactorAuthentication = () => {

    const [showQRPopup, setShowQRPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [authStatus, setAuthStatus] = useState('');

    const checkAuthStatus = async () => {
        setLoading(true);
        try {
            const result = await check2FAAuthStatus();
            setAuthStatus(result?.data?.mfa_status)
        } catch (err) {
            setError(err?.message ?? "")
            // console.log(err)
        } finally {
            setLoading(false);
        }
    };


    const disable2FAuth = async () => {
        setLoading(true);
        try {
            const result = await disable2FAuthentication();
            setSuccess(result.message);
            setTimeout(() => {
                window.location.reload();
            },3000)            
        } catch (err) {
            setError(err?.message ?? "")
            // console.log(err)
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        checkAuthStatus();
    }, [])


    return (
        <>

            {showQRPopup &&
                <EnableTwoFactorAuthentication
                    show={showQRPopup}
                    handleClose={() => setShowQRPopup(false)}
                    setError={setError}
                    setSuccess={setSuccess}
                />
            }

            {loading && <LoadingIndicator />}

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3 col-xs-12 text-right">
                        <Button className="f-12 pl-0" variant="link" style={{ color: '#FC7D72' }}>
                            <Link className="backtoclr" to={BASE_PATH}>
                                {'< '}
                                Back to Client Master
                            </Link>
                        </Button>
                    </div>
                    <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12 pt-4">
                        <div className="card gst_factorauth_using_gaccount_cardbox">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="gst_factorauth_using_gaccount_leftimage">
                                        <img src={authIcon} width="100%" alt='auth' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="gst_factorauth_using_gaccount_titlefs ">
                                        Enable Two-Factor Authentication (2FA) to enhance security and protect your account.
                                    </h4>
                                    <p className="gst_factorauth_using_gaccount_textfsclr">
                                        Protect your account by requiring a time-sensitive OTP generated by authenticator apps,
                                        adding an extra layer of defense against unauthorized access.
                                    </p>

                                    <div className="row pt-2">
                                        <div className="col-md-12 text-center">
                                            {parseInt(authStatus) === 1 ?
                                                <button className="btn btn-default gst_factorauth_gauthbtn_bgclr" onClick={() => disable2FAuth()}>
                                                    <span className="pr-1">
                                                        <img src={delIcon} alt='delete' width="16" style={{ position: "relative", verticalAlign: "bottom" }} />
                                                    </span>
                                                    Delete Authentication
                                                </button>
                                                :
                                                <button className="btn btn-default gst_factorauth_gauthbtn_bgclr" onClick={() => setShowQRPopup(true)}>
                                                    Set Up Now
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TwoFactorAuthentication