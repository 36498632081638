import { useEffect } from "react";
import { PAYU_CHECKOUT_SCRIPT_SRC } from "../app/constants";

const PayUCheckout = (props) => {

  const { setError, setLoading, paymentData, handleClose, handleProceed } = props;

  // Load the PayU script dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.src = PAYU_CHECKOUT_SCRIPT_SRC;
    script.async = true;
    script.onload = () => {
      handlePayment();
    };
    script.onerror = () => {
      if (setError) setError("Failed to load PayU Bolt script");
      if (handleClose) handleClose();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    //eslint-disable-next-line
  }, []);


  const handlePayment = async () => {

    const handlers = {
      responseHandler: (BOLT) => {
        if (BOLT.response.txnStatus === "SUCCESS") {
          if (handleProceed) handleProceed(BOLT.response);
        }
        if (BOLT.response.txnStatus === "FAILED") {
          if (setError) setError('Payment failed. Please try again.');
          if (setLoading) setLoading(false);
        }
        if (BOLT.response.txnStatus === "CANCEL") {
          if (setError) setError('Payment process cancelled. Please try again.');
          if (setLoading) setLoading(false);
        }

        if (handleClose) handleClose();
      },
      catchException: (BOLT) => {
        if (setError) setError('Payment failed. Please try again.');
        if (setLoading) setLoading(false);
        if (handleClose) handleClose();
      },
    };

    if (window.bolt) {
      window.bolt.launch(paymentData, handlers);
    } else {
      if (setError) setError("PayU Payment widget not loaded");
    }
  };

  return (null)

};

export default PayUCheckout;