import axios from "axios";
import { BASE_PATH, BASE_URL } from "../app/constants";
import {
  getFirstError,
  handleErrorWithCode,
  handleErrorWithCodeFuv,
  setHeaderValues,
} from "./responseHandler";
import { getConfig } from "./common";
import { clearAuthToken } from "../utils/AuthToken";
import { redirectToLoginPage, saveRedirectUrl } from "../utils/UtilityFunctions";
import history from '../app/history';




 function handleErrorForFVU(err) {
  if (typeof err === 'string') {
    return {
      code: 200,
      message: err,
    };
  }

  if (!err?.response) {
    return {
      code: 404,
      message: err.toString(),
    };
  }

  if (err?.response?.status === 401) {
    clearAuthToken()
    setHeaderValues({ header_fail: err?.response?.data?.errors });
    saveRedirectUrl();
    redirectToLoginPage();
  }

  if (err?.response?.status === 403) {
    history.push(`${BASE_PATH}`);
  }


  if (err?.response?.status === 500) {
    return {
      code: 500,
      message: 'Something went wrong. Please try again after sometime!',
    };
  }

  if(err?.response?.data instanceof Blob &&  err?.response?.data?.type === "application/json") { 
    return err;
  }

  if (err?.response?.data) {
    return {
      code: err?.response?.status,
      message: getFirstError(err?.response?.data?.errors),
    };
  }

  return {
    code: 500,
    message: err.toString(),
  };
}


export async function downloadFVUFile(getParams) {

    const url = `${BASE_URL}/api/v1/returns/download-fvu-file${getParams}`;
    try {
      const config = { ...getConfig() };
      config.responseType = 'blob';
      const result = await axios.get(url, config);
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  
  }

  export async function checkFVUFileStatus(getParams) {

    const url = `${BASE_URL}/api/v1/returns/status-generate-fvu-file${getParams}`;
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCodeFuv(err);
    }
  }

  export async function generateFVUFile(getParams) {

    const url = `${BASE_URL}/api/v1/returns/start-generate-fvu-file${getParams}`;
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCodeFuv(err);
    }
  }

  export async function getFvuRegularOltasCaptcha() {
    const url = `${BASE_URL}/api/v1/oltas/captcha`;
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function getFvuRegularOltasCSI(data) {
    const url = `${BASE_URL}/api/v1/oltas/download-csi`;
  
    try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function fvuRegularDownloadCSI(data) {
    const url = `${BASE_URL}/api/v1/efiling/download-csi`;
  
    try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
  
      return result.data;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }

  export async function generateRegularFVUFileWithConnector(getParams) {
    const url = `${BASE_URL}/api/v1/usuc/efiling/get-fvu-file${getParams}`;  
  
    try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
        throw (result.data.message);
      }
      return result;
    } catch (err) {
      throw handleErrorWithCode(err);
    }
  }
