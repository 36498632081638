/* eslint-disable no-unused-expressions */
/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { PLACE_OF_SUPPLY_LIST, BASE_PATH, RAZORPAY_PAYMENT_KEY } from '../../app/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Exgstlogo from '../../images/logo/extdslogo.png';
import {
    faTimesCircle,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { getBillingData, applyDiscountVoucher, applyAddOnDiscountVoucher, generatePaymentOrder, generateAddOnPaymentOrder, refreshToken, sharePaymentLink } from '../../api/billingAPI';
import { formatNumber, redirectToLoginPage } from '../../utils/UtilityFunctions';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import './Billing.css';
import { validateGSTNumber } from '../../utils/UtilityFunctions';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { setHeader, selectHeaderData } from '../header/headerSlice';
import { initialHeaderData } from '../../app/constants';
import { useDispatch, useSelector } from "react-redux";
import { clearAuthToken, updateAuthToken } from '../../utils/AuthToken';
import whatsappicon from '../../images/whatsappicon.png';
import edite02 from '../../images/edite02.png';
import NumericInput from '../../components/NumericInput';
import PayUCheckout from '../../components/PayUCheckout';

const Billing = (props) => {

    const Razorpay = useRazorpay();
    const { planData, sharedplanid, allowOtherPaymentmode, setBillingDetails, billingDetails, setShowPaymentmode, setShowPlanBilling, history, addOnData } = props;
    const [discountVoucherData, setDiscountVoucherData] = React.useState(false);
    const [discountVoucherCode, setDiscountVoucherCode] = React.useState('');
    const [showDiscountVoucher, setShowDiscountVoucher] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const [formErrors, setFormError] = React.useState({});

    const location = useLocation();
    const params = queryString.parse(location.search);
    const changePlanStatus = parseInt(params?.change_plan ?? 0);
    const [disable, setDisable] = useState(false);
    const dispatch = useDispatch();

    const addOnPlan = addOnData ?? null;
    //const [addOnTotal, setAddOnTotal] = React.useState(0);

    const headerdata = useSelector(selectHeaderData);
    const [showShareButton, setShowShareButton] = useState(false);
    const [shareMobileNo, setShareMobileNo] = useState('');
    const [editSharePlanMobileNo, setEditSharePlanMobileNo] = useState(false);
    const [sharePlanError, setSharePlanError] = useState('');
    const [payUPaymentData, setPayUPaymentData] = useState({});
    const [runPayUCheckout, setRunPayUCheckout] = useState(false);

    const logOut = () => {
        clearAuthToken();
        dispatch(setHeader(initialHeaderData));
        redirectToLoginPage();
    };

    const getBillingDetails = React.useCallback(async () => {
        setLoading(true);
        try {
            const result = await getBillingData();
            let new_billingDetails = { ...billingDetails };
            if (result.data.mobile_no) { new_billingDetails.tenant_contact_person.mobile_no = result.data.mobile_no }
            if (result.data.email) { new_billingDetails.tenant_contact_person.email = result.data.email }
            if (result.data.tenant_address) { new_billingDetails.tenant_address = result.data.tenant_address }
            if (result.data.tenant_contact_person) { new_billingDetails.tenant_contact_person = result.data.tenant_contact_person }

            if (result?.data?.tenant_address?.company_gst_number) {
                const gstin = result?.data?.tenant_address?.company_gst_number;
                const stateCode = gstin.slice(0, 2);
                new_billingDetails.tenant_address.state_id = parseInt(stateCode);
                setDisable(true);
            }

            setBillingDetails(new_billingDetails);

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false)
        }
        //eslint-disable-next-line
    }, [setBillingDetails]);

    const refreshSubscription = React.useCallback(async () => {
        try {
            let result = await refreshToken();
            if (result.data.token) {
                setLoading(false);
                updateAuthToken(result.data.token);
                setSuccess("Thank you for choosing Express TDS! Your payment has been received Successfully.");
                setTimeout(function () { history.push(`${BASE_PATH}`); }, 2000);
            }

        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
        //eslint-disable-next-line
    }, [setBillingDetails])


    const applyVoucher = async () => {
        setLoading(true);
        let form_data = {};
        form_data['discount_voucher_code'] = discountVoucherCode;
        if (planData.plan_id) {
            form_data['plan_id'] = planData.plan_id;
        }

        if (planData.plan_version_id) {
            form_data['plan_version_id'] = planData.plan_version_id;
        }

        if (addOnPlan !== null) {
            form_data['parameters'] = addOnPlan.parametersList
        }

        if (changePlanStatus === 1) {
            form_data['change_plan'] = changePlanStatus;
        }

        try {
            let result = '';
            if (addOnPlan !== null) {
                result = await applyAddOnDiscountVoucher(form_data);
                addOnData.plan_amt = result?.data?.plan_amt ?? 0;
                addOnData.discount_amt = result?.data?.discount_amt ?? 0;
                addOnData.tax_rate = result?.data?.tax_rate ?? 0;
                addOnData.tax_amt = result?.data?.tax_amt ?? 0;
                addOnData.payment_amt = result?.data?.payment_amt ?? 0;
                setDiscountVoucherData(result.data);
            } else {
                result = await applyDiscountVoucher(form_data);
                setDiscountVoucherData(result.data);
            }

            setSuccess("Discount voucher applied successfully");
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setDiscountVoucherData(false);
            setLoading(false);
        }
    }

    const handleAddressChange = (field, value) => {
        let new_billingDetails = { ...billingDetails };
        new_billingDetails['tenant_address'][field] = value;
        setBillingDetails(new_billingDetails);
    }

    const handleGstnChange = (gstin) => {
        if (validateGSTNumber(gstin)) {
            const stateCode = gstin.slice(0, 2);
            let new_billingDetails = { ...billingDetails };
            new_billingDetails['tenant_address']['state_id'] = parseInt(stateCode);
            setBillingDetails(new_billingDetails);
            setDisable(true);
        } else {
            setDisable(false);
        }
    }

    const handleContactChange = (field, value) => {
        let new_billingDetails = { ...billingDetails };
        new_billingDetails['tenant_contact_person'][field] = value;
        setBillingDetails(new_billingDetails);
    }


    const startPayment = async (e, paymentModekey) => {
        e.preventDefault();
        let new_formErrors = {};
        const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        const pincode = new RegExp(/^[1-9][0-9]{5}$/);

        if (!billingDetails?.tenant_address?.company_name.replace(/<\/?[^>]+(>|$)/g, "")) {
            new_formErrors['company_name'] = "Please enter Company Name";
        }
        if (billingDetails?.tenant_address?.company_gst_number && !validateGSTNumber(billingDetails?.tenant_address?.company_gst_number)) {
            new_formErrors['company_gst_number'] = "Please enter valid Company GSTIN";
        }
        if (!billingDetails?.tenant_address?.city.replace(/<\/?[^>]+(>|$)/g, "")) {
            new_formErrors['city'] = "Please enter City Name";
        }
        if (!billingDetails?.tenant_address?.address_detail.replace(/<\/?[^>]+(>|$)/g, "")) {
            new_formErrors['address_detail'] = "Please enter Address";
        }
        if (!pincode.test(billingDetails?.tenant_address?.pincode)) {
            new_formErrors['pincode'] = "Please enter Valid Pincode";
        }
        if (!billingDetails?.tenant_address?.state_id) {
            new_formErrors['state_id'] = "Please select State name";
        }
        if (!billingDetails?.tenant_contact_person?.full_name.replace(/<\/?[^>]+(>|$)/g, "")) {
            new_formErrors['full_name'] = "Please enter Contact Person Name";
        }
        if (billingDetails?.tenant_contact_person?.mobile_no < 5999999999 || billingDetails?.tenant_contact_person?.mobile_no > 9999999999) {
            new_formErrors['mobile_no'] = "Please enter Valid Mobile Number";
        }
        if (!emailRegex.test(billingDetails?.tenant_contact_person?.email)) {
            new_formErrors['email'] = "Please enter Valid Email Address";
        }
        setFormError(new_formErrors);
        if (Object.keys(new_formErrors).length === 0) {
            if (paymentModekey) {
                genrateOrder();
            } else if (!paymentModekey) {
                let data = {
                    tenant_address: billingDetails?.tenant_address,
                    tenant_contact_person: billingDetails?.tenant_contact_person,
                    discount_voucher_id: (discountVoucherData?.['discount_voucher_id']) ?? ''
                }
                setShowPaymentmode(data);
            }

        }

    }


    const genrateOrder = async () => {
        setLoading(true);
        try {
            let form_data = {};
            let result = '';
            if (addOnPlan !== null) {
                form_data['parameters'] = addOnPlan?.parametersList ?? [];
                form_data['discount_voucher_id'] = (discountVoucherData?.['discount_voucher_id']) ?? '';
                form_data['tenant_address'] = billingDetails.tenant_address;
                form_data['tenant_contact_person'] = billingDetails.tenant_contact_person;
                result = await generateAddOnPaymentOrder(form_data);

            } else {
                form_data['plan_id'] = planData.plan_id;
                form_data['plan_version_id'] = planData.plan_version_id;
                form_data['discount_voucher_id'] = (discountVoucherData?.['discount_voucher_id']) ?? '';
                form_data['tenant_address'] = billingDetails.tenant_address;
                form_data['tenant_contact_person'] = billingDetails.tenant_contact_person;
                if (changePlanStatus === 1) {
                    form_data['change_plan'] = changePlanStatus;
                }
                result = await generatePaymentOrder(form_data);
            }

            setLoading(false);

            if (result.data.razorpay_orderid) {
                handlePayment(result.data.razorpay_orderid, result.data.razorpay_order_amount);
            }
            else if (result?.data?.payu_hash) {
                // PayU requires data at "hash" key
                const payUData = {hash: result?.data.payu_hash, ...result?.data};
                setPayUPaymentData(payUData);
                setRunPayUCheckout(true);
            }
            else if (result.data.subscription_applied) {
                setSuccess("Thank you for choosing Express TDS! Your payment has been received Successfully.");
                setTimeout(function () { logOut(); }, 3000);
            }
            else {
                setError("Something went wrong! Please try again after sometime.");
            }


        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    }

    const handlePayment = (razorpay_orderid, razorpay_order_amount) => {

        const options: RazorpayOptions = {
            key: RAZORPAY_PAYMENT_KEY,
            amount: parseFloat(razorpay_order_amount),
            currency: "INR",
            name: "Express TDS",
            description: "K.D.K. Softwares (India) Pvt. Ltd.",
            image: Exgstlogo,
            order_id: razorpay_orderid,
            handler: (res) => {
                if (res.razorpay_payment_id && res.razorpay_order_id === razorpay_orderid) {
                    setLoading(true);
                    setTimeout(function () { refreshSubscription(); }, 10000);
                }
            },
            prefill: {
                name: billingDetails.tenant_contact_person.full_name,
                email: billingDetails.tenant_contact_person.email,
                contact: billingDetails.tenant_contact_person.mobile_no,
            },
            notes: {
                address: billingDetails.tenant_address.address_detail,
            },
            theme: {
                color: "#31719b",
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.on("payment.failed", function (response) {
            alert(response.error.description);
        });

        rzpay.open();
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            let activeElement = document.activeElement.className;
            if (activeElement.includes('voucher_code')) {
                applyVoucher();
                e.preventDefault();
            }
        }
    }

    React.useEffect(() => {
        if (!sharedplanid) {
            getBillingDetails();
        }
        //eslint-disable-next-line
    }, []);

    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        let error = '';
        if ((Number(value) < 5999999999 || Number(value) > 9999999999)) {
            error = 'Invalid mobile number'
        }
        setSharePlanError(error);
    }

    useEffect(() => {
        setShareMobileNo(headerdata?.mobile_number);
    }, [headerdata])

    const shareSelectedPlanOnWhatApp = async() => {        
        let payload = {
            country_code: "+91",
            mobile_no: shareMobileNo,
            name: headerdata?.full_name,
        }
        if (addOnPlan !== null) {
            payload['parameters'] = addOnPlan?.parametersList ?? [];
            payload['discount_voucher_id'] = discountVoucherData?.['discount_voucher_id'] ?? null;
            payload['tenant_address'] = billingDetails.tenant_address;
            payload['tenant_contact_person'] = billingDetails.tenant_contact_person;
        }else{
            payload['plan_id'] = planData.plan_id;
            payload['plan_version_id'] = planData.plan_version_id;
            payload['discount_voucher_id'] = discountVoucherData?.['discount_voucher_id'] ?? null;
            payload['tenant_address'] = billingDetails.tenant_address;
            payload['tenant_contact_person'] = billingDetails.tenant_contact_person;
            if (changePlanStatus === 1) {
                payload['change_plan'] = changePlanStatus;
            }
        }

        try{
            setLoading(true);
            const result = await sharePaymentLink(payload, addOnPlan);
            setSuccess(result?.message);
        }catch(err){
            setError(err?.message);
            console.log('Error',err);
        }finally{
            setLoading(false);
        }
    }

    return (
        <div className="container">
            <ShowAlert
                success={success}
                error={error}
                onClose={() => {
                    setSuccess(null);
                    setError(null);
                }}
            />
            {loading ? <LoadingIndicator /> : null}

            {
                runPayUCheckout &&
                <PayUCheckout 
                    setError={setError} 
                    setSuccess={setSuccess}
                    setLoading={setLoading}
                    paymentData={payUPaymentData} 
                    handleClose={() => {
                        setRunPayUCheckout(false)
                    }}
                    handleProceed={() => {    
                        setLoading(true);
                        setTimeout(function () { refreshSubscription(); }, 10000);  
                    }}
                />                
            }
            
            <div className="row mt-3">
                <div className="col-md-12 text-center">
                    <div>
                        <span onClick={() => {
                            if (!sharedplanid) {
                                setShowPlanBilling(false)
                            } else if (sharedplanid) {
                                history.push(`${BASE_PATH}`)
                            }
                        }
                        }
                            className="billinginfor_backtoclr">&lt; {!sharedplanid ? "Back" : "Back to Deductor List"} </span>
                        <span className="billinginfortitlefs">Billing Information</span>
                    </div>
                </div>
            </div>
            <form onSubmit={startPayment} autoComplete="off">
                <div className="row mt-3">
                    <div className="col-md-9">
                        <div className="billinginforform">
                            <div className="row mt-2">
                                <div className="form-group col-sm-6">
                                    <label for="">
                                        Company Name<span className="red_text">*</span>
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Company Name" name="company_name" value={billingDetails?.tenant_address?.company_name ?? ''} onChange={(e) => handleAddressChange('company_name', e.target.value)} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.company_name}</div>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label for="">Company GSTIN</label>
                                    <input type="text" className="form-control" placeholder="Enter Company GSTIN" name="gstin" value={billingDetails?.tenant_address?.company_gst_number ?? ''} onChange={(e) => handleAddressChange('company_gst_number', e.target.value)} onBlur={(e) => handleGstnChange(e.target.value)} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.company_gst_number}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-3">
                                    <label for="">
                                        Office/Work/Flat Address<span className="red_text">*</span>
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Address" name="address" value={billingDetails?.tenant_address?.address_detail ?? ''} onChange={(e) => handleAddressChange('address_detail', e.target.value)} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.address_detail}</div>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label for="">Pincode<span className="red_text">*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter Pincode" name="pincode" maxLength={6} value={billingDetails?.tenant_address?.pincode ?? ''} onChange={(e) => handleAddressChange('pincode', e.target.value)} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.pincode}</div>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label for="">City</label><span className="red_text">*</span>
                                    <input type="text" className="form-control" placeholder="Enter City" name="city" value={billingDetails?.tenant_address?.city ?? ''} onChange={(e) => handleAddressChange('city', e.target.value)} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.city}</div>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label for="">State</label><span className="red_text">*</span>
                                    <select disabled={disable ? true : null} className="form-control" name="state" value={billingDetails?.tenant_address?.state_id ?? ''} onChange={(e) => handleAddressChange('state_id', e.target.value)}>
                                        <option value=""> Select State</option>
                                        {PLACE_OF_SUPPLY_LIST.map((list, index) => {
                                            return (<option value={parseInt(list.value, 10)}> {list.label}</option>)
                                        })}
                                    </select>
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.state_id}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-3">
                                    <label for="">
                                        Contact Person Name<span className="red_text">*</span>
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Contact Person" value={billingDetails?.tenant_contact_person?.full_name ?? ''} onChange={(e) => handleContactChange('full_name', e.target.value)} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.full_name}</div>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label for="">Mobile No.</label><span className="red_text">*</span>
                                    <input type="text" className="form-control" placeholder="Mobile No" name="mobile" maxLength={10} value={billingDetails?.tenant_contact_person?.mobile_no ?? ''} readOnly={true} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.mobile_no}</div>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label for="">Email Address</label><span className="red_text">*</span>
                                    <input type="email" className="form-control" placeholder="Email" name="email" value={billingDetails?.tenant_contact_person?.email ?? ''} readOnly={true} />
                                    <div role="alert" className="k-form-error k-text-start">{formErrors.email}</div>
                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className={`form-group ${allowOtherPaymentmode ? 'col-sm-6 text-right' : 'col-sm-12 text-center'} `}>
                                <span class="pr-2">
                                        <button type='button' class="btn btn-default Subscriptionplan_whatsapp_btnbgclr subscript_whatsappicon_clickshow" onClick={() => setShowShareButton(true)}  >
                                            <span class="Subscriptionplan_whatsapp_icon">
                                                <img src={whatsappicon} width="18" alt='' />
                                            </span>
                                            <span class="Subscriptionplan_whatsapp_cardbox Subscriptionplan_whatsapp_textline">
                                                |
                                            </span>
                                            Share
                                        </button>
                                    </span>

                                    {
                                        showShareButton ?
                                            <div class={`subscript_whatsappicon_hidebox`} >
                                                <div class="row">
                                                    <div class="form-group subscript_whatsappicon_brpdiv col-sm-8 mb-0">
                                                        <div class="input-group mb-0">
                                                            <div class="input-group-prepend subscript_whatsappicon_apprendleft subscript_whatsappicon_clickshow" >
                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                            </div>
                                                            <NumericInput
                                                                disabled={!editSharePlanMobileNo}
                                                                type="text"
                                                                class={`form-control subscript_whatsappicon_inputbox`}
                                                                value={shareMobileNo}
                                                                maxLength={10}
                                                                placeholder="WhatsApp Mobile Number"
                                                                onChange={(e) => {
                                                                    setShareMobileNo(e.target.value);                                                                    
                                                                }}
                                                                onBlur={(e) => {
                                                                    handleMobileNumberChange(e)
                                                                }}

                                                            />
                                                            <div class="input-group-prepend subscript_whatsappicon_apprendright">
                                                                <img alt='' src={edite02} width="16" onClick={() => { setEditSharePlanMobileNo(true) }} />
                                                            </div>
                                                        </div>
                                                        {sharePlanError ? <span className="error_message"> {sharePlanError} </span> : null}
                                                    </div>

                                                    <div class="form-group col-sm-4 mb-0">
                                                        {
                                                            sharePlanError
                                                                ? <button type='button' class="btn btn-default subscript_whatsappicon_sharebtn disabled">
                                                                    Share
                                                                </button>
                                                                : <button type='button' class="btn btn-default subscript_whatsappicon_sharebtn" onClick={() => {
                                                                    shareSelectedPlanOnWhatApp()
                                                                }}>
                                                                    Share
                                                                </button>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            : null
                                    }
                                    <button
                                        className="btn btn-default makepaybtn"
                                        onClick={(e) => {
                                            startPayment(e, 'makePayment')
                                        }}> Make Payment</button>
                                </div>
                                {allowOtherPaymentmode && <div className="form-group text-right">
                                    <button className="btn btn-default makepaybtn" onClick={(e) => startPayment(e)}>Made Payment through Cash, Cheque, NEFT, RTGS, IMPS</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card rightsummbox">
                            <div className="summinnerbox">
                                <h4 className="billingsumtextfs">
                                    Summary
                                </h4>
                            </div>
                            <div className="summinnerbodybgclr">
                                {addOnData
                                    ?
                                    <>
                                        <div className="plansheltextfs" style={{ float: 'left', paddingLeft: '5px', paddingTop: '5px' }}>Addon Details:</div>
                                        <Tooltip anchorElement="target" position="top">
                                            <div className="pl-3 compannameovertooltip" data-toggle="tooltip"
                                                data-placement="top"
                                                title={addOnData?.addon_details}
                                                style={{ paddingTop: '5px' }}
                                            >
                                                {addOnData?.addon_details}
                                            </div>
                                        </Tooltip>
                                    </>
                                    :
                                    <>
                                        <p className="planseltextbox">
                                            <span className="plansheltextfs ">
                                                Plan Selected:
                                            </span>
                                            <span className="plannotexts pl-3">
                                                {planData.plan_name}
                                            </span>
                                        </p>
                                    </>
                                }


                                <p className="planseltextbox overflowtexthtovoch">
                                    {!showDiscountVoucher &&
                                        <span className="howtovouch">
                                            <span data-toggle="tooltip" data-placement="top" title="How to Voucher Code?" onClick={() => setShowDiscountVoucher(true)}>Have a Voucher Code ?</span>
                                        </span>}
                                    {showDiscountVoucher &&
                                        <div className="form-group">
                                            <input type="text" className="form-control voucher_code" onKeyPress={(e) => handleEnterKeyPress(e)} placeholder="Voucher Code" value={discountVoucherCode} onChange={(e) => { setDiscountVoucherCode(e.target.value) }} style={{ width: '85%', display: 'inline-block' }} maxLength={10} />
                                            <span className="apply_btn ml-1" onClick={() => applyVoucher()}><FontAwesomeIcon icon={faCheckCircle} /></span>
                                            <span className="cancel_btn ml-1" onClick={() => { setShowDiscountVoucher(false); setDiscountVoucherCode(''); setDiscountVoucherData(false); }}><FontAwesomeIcon icon={faTimesCircle} /></span>

                                        </div>}
                                </p>
                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                    <table className="table table-borderless billsummboxtb">
                                        <tbody>
                                            {addOnData
                                                ?
                                                <>
                                                    <tr>
                                                        <td>
                                                            Addon Amt:
                                                        </td>
                                                        <td className="text-right">{formatNumber(addOnData?.plan_amt)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Discount:
                                                        </td>
                                                        <td className="text-right">{formatNumber(addOnData?.discount_amt)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GST @ {addOnData?.tax_rate}%:</td>
                                                        <td className="text-right">{formatNumber(addOnData?.tax_amt)}</td>
                                                    </tr>

                                                    <tr className="trrowtopbor">
                                                        <td>
                                                            Total Amount:
                                                        </td>
                                                        <td className="text-right totaltextfs">{formatNumber(addOnData?.payment_amt)}</td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td>
                                                            Subscription Amt:
                                                        </td>
                                                        <td className="text-right">{formatNumber(discountVoucherData ? discountVoucherData.plan_amt : planData.plan_amt ?? '')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Discount:
                                                        </td>
                                                        <td className="text-right">{formatNumber(discountVoucherData ? discountVoucherData.discount_amt : planData.discount_amt ?? '')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GST @ {planData.tax_rate}%:</td>
                                                        <td className="text-right">{formatNumber(discountVoucherData ? discountVoucherData.tax_amt : planData.tax_amt ?? '')}</td>
                                                    </tr>


                                                    <tr className="trrowtopbor">
                                                        <td>
                                                            Total Amount:
                                                        </td>
                                                        <td className="text-right totaltextfs">{formatNumber(discountVoucherData ? discountVoucherData.payment_amt : planData.payment_amt)}</td>
                                                    </tr>
                                                </>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div >
    );
};

export default Billing;
