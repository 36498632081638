import React from 'react';
import './Plans.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import gstplanImg from '../../images/gstplan_img.png';
// import discountOffer from '../../images/discountsaleoffer.jpg';
import { selectHeaderData } from "../header/headerSlice";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useSelector } from 'react-redux';
import DIAMOND_PLAN from "../../images/diamond.gif"

const PlanTable = ({ history, ...props }) => {

    let { currentPlan, plans, setShowPlanBilling, isPlansLoading, setVisible } = props;
    const headerdata = useSelector(selectHeaderData);
    const plans_div_width = Math.floor(Math.max(16, Math.min(30, 100 / (plans.length + 1))));

    const DEFAULT_IMAGE_PURPLE = 'https://express-v2.s3.ap-south-1.amazonaws.com/icon_img/diamond_purple.gif';
    // const DEFAULT_IMAGE_BLUEGREEN = 'https://express-v2.s3.ap-south-1.amazonaws.com/icon_img/diamond_bluegreen.gif';
    

    return (
        <>
            <div className="row">
                <div className="container">
                    <div className="row mt-2">
                        <div className="col-md-12 text-center">
                        </div>
                    </div>
                    <div className="justify-content-center plantab_bgclr">
                        {plans.length > 0 && (
                            <div className="prof4colcolumns plantable_leftimage_listtexfs" style={{ width: "25%", paddingTop: "39px" }}>
                                <ul className="price">
                                    <li className="typepricecol">
                                        <img src={gstplanImg} height="157" alt="img" />
                                    </li>
                                    {plans.length && plans[0].params.map((param) => (
                                        <li className="planparam" key={param.display_text}>{param.display_text}</li>
                                    ))}
                                    <li className="planpricetextclr py-3">Price</li>
                                </ul>
                            </div>
                        )}
                    </div>

                    {isPlansLoading ? (
                        <LoadingIndicator />
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="currentplan_mainbox">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="" id="gstprofessional_plan_tab02" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className="row mt-2">

                                                {Object.keys(currentPlan)?.length > 0 &&
                                                    plans?.length > 0 &&
                                                    plans.filter(plan => !plan.is_current_plan).length > 0 && (
                                                        <div className="col-md-3 text-center gstplan_migratplan_widthbox"></div>
                                                    )
                                                }


                                                <div className="col-md-12">
                                                    <div class="gstplan_migratplan_innermaindiv">
                                                        {/* currentplan_leftcardbox */}
                                                        <div class="gstplan_migratplan_leftcolwidth_box">
                                                            <div class=" gstplan_migratplan_leftwidthbox">&nbsp;</div>
                                                            {currentPlan && Object.keys(currentPlan)?.length > 0 &&
                                                                <div className="prof4colcolumns" style={{ width: plans_div_width + "%",  marginRight: '9px' }} key={currentPlan?.plan_id}>
                                                                    <ul className="price gstcurentplan_boxborder_greyclr">
                                                                        <li className="headertopbox plantinumbox currentplan_topbocx_bgclr_blue">
                                                                            <div className="catgsilvericon">
                                                                                <img src={
                                                                                    currentPlan?.graphics_value
                                                                                        ? currentPlan?.graphics_value
                                                                                        : DEFAULT_IMAGE_PURPLE
                                                                                } alt={currentPlan?.plan_name} width="80" />
                                                                            </div>
                                                                            <button className="btn btn-default pricetypgoldbtn silvertextclr user_currentplan_name" style={{ position: 'relative', top: '5px'}}>
                                                                                <span class="user_currentplan_nametitle">{currentPlan?.plan_name}</span>
                                                                            </button>
                                                                            <div className="headerbottonborderbox"></div>
                                                                        </li>

                                                                        {currentPlan?.params?.map((param, index2) => {

                                                                            return (<li className="sliver_platbgclr"> {param.value}</li>)
                                                                        })}
                                                                        {currentPlan?.params?.length === 0 && <li className="sliver_platbgclr" style={{ marginTop: "15px" }}>-</li>}

                                                                        <li className="py-3">
                                                                            <span className="distextclr">
                                                                                <FontAwesomeIcon icon={faRupeeSign} />
                                                                            </span>
                                                                            <span className="pricetextfs">{currentPlan?.plan_amt}</span>
                                                                            <sub><span className="distextclr" style={{ fontSize: '16px' }}>/{currentPlan?.plan_duration_txt}</span></sub>
                                                                        </li>
                                                                        <li>
                                                                            <button onClick={() => { setShowPlanBilling(currentPlan) }} className="btn btn-default Proceedbtntextclr_coral">
                                                                                Current Plan
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div class="gstplan_migratplan_rightcolwidth_box">
                                                            <div class="gstplan_migratplan_box">
                                                                {Object.keys(currentPlan)?.length > 0 &&
                                                                    plans?.length > 0 &&
                                                                    plans.filter(plan => !plan.is_current_plan).length > 0 && (
                                                                        <div className="text-center gstplan_migratplan_widthbox">
                                                                            <div className="gstplan_migratplan_box">
                                                                                <div className="gstplan_migratplan_fs">
                                                                                    <h4>Plans available for Migration</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>

                                                            {plans.map((plan, index) => {
                                                                if (plan.is_current_plan) return null;
                                                                return (
                                                                    <div className="prof4colcolumns" style={{ width: plans_div_width + "%", paddingLeft: `${index === 0 ? "1px" : "0px"}` }} key={plan.plan_id}>
                                                                        <ul className="price">
                                                                            <li className="headertopbox plantinumbox">
                                                                                <div className="catgsilvericon">
                                                                                    <img src={
                                                                                        plan.graphics_value
                                                                                            ? plan.graphics_value
                                                                                            : DIAMOND_PLAN
                                                                                    } alt={plan.plan_name} width="80" />
                                                                                </div>
                                                                                <button className="btn btn-default pricetypgoldbtn silvertextclr" style={{marginBottom: `${Object.keys(currentPlan)?.length > 0 ? '' : '32px'}`}}>
                                                                                    {plan.plan_name}
                                                                                </button>
                                                                                <div className={(parseInt(index) + 1) % 2 === 0 ? "headerbottonborderbox" : "headerbottonborderpaltbox"}></div>
                                                                            </li>

                                                                            {plan.params.map((param, index2) => (
                                                                                <li key={index2} className="sliver_platbgclr">
                                                                                    {param.value}
                                                                                </li>
                                                                            ))}
                                                                            {plan.params.length === 0 && <li style={{ marginTop: "15px" }}>-</li>}

                                                                            <li className="py-3">
                                                                                <span className="distextclr">
                                                                                    <FontAwesomeIcon icon={faRupeeSign} />
                                                                                </span>
                                                                                <span className="pricetextfs">{plan.plan_amt}</span>
                                                                                <sub><span className="distextclr" style={{ fontSize: '16px' }}>/{plan.plan_duration_txt}</span></sub>
                                                                            </li>
                                                                            <li>
                                                                                {plan?.is_current_plan ?
                                                                                    <button onClick={() => { setShowPlanBilling(plan) }} className="btn btn-default Proceedbtntextclr_coral">
                                                                                        Current Plan
                                                                                    </button>
                                                                                    :
                                                                                    <button onClick={() => { setShowPlanBilling(plan) }} className="btn btn-default Proceedbtntextclr">
                                                                                        Select
                                                                                    </button>
                                                                                }
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )


                                                            })}

                                                            {/* Talk to Us button */}
                                                            {!currentPlan && headerdata?.organization_type !== "CA" && (
                                                                <div className="prof4colcolumns" style={{ width: plans_div_width + "%" }}>
                                                                    <ul className="price" style={{ width: "100%", boxShadow: "none", border: "none" }}>
                                                                        <li className="headertopbox goldbox">
                                                                            <div className="catgsilvericon">
                                                                                <img src={DEFAULT_IMAGE_PURPLE} alt="Enterprise Plan" width="80" />
                                                                            </div>
                                                                            <button className="btn btn-default pricetypgoldbtn silvertextclr">
                                                                                {'ENTERPRISE PLAN'}
                                                                            </button>
                                                                            <div className={"headerbottonborderbox"}></div>
                                                                        </li>
                                                                        <li className="headertopbox goldbox" style={{ marginTop: '1rem', height: '9rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <button onClick={() => { setVisible(true) }} className="btn btn-default Proceedbtntextclr">Talk to Us</button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 pt-2">
                                <p className="pricecontfs mb-0">Price is exclusive of Taxes.</p>
                            </div>

                            <div className="col-md-12 text-right">
                                <p className="tremcondtextfs"><sup>*</sup> Terms and conditions applicable. </p>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default PlanTable;
