import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import TwoFactorAuthVerify from './TwoFactorAuthVerify';
import QRCode from "react-qr-code";
import { generate2faQRCodeForAuth, verify2FAAuthOtp } from '../../api/authenticationAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

const EnableTwoFactorAuthentication = (props) => {

  const { show, handleClose, setError, setSuccess } = props;

  const [loading, setLoading] = useState(false);   
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [authOTP, setAuthOTP] = useState(null);

  const generateQRForAuth = async () => {
      setLoading(true)
      try {
          const result = await generate2faQRCodeForAuth();
          setQrCodeUrl(result?.data?.mfa_url ?? "");
      } catch (err) {
          // console.log(err)
      } finally {
          setLoading(false);
      }
  };

  const handleSuccess = async(e) => {
      if(!authOTP) return false;
      setLoading(true);
      try{
          const data = {
              mfa_otp: authOTP,
          }
          const result = await verify2FAAuthOtp(data)
          setSuccess(result.message);
          setTimeout(() => {
              window.location.reload()
          },2000)
      }catch(err){
          setError(err.message);
      }finally{
          setLoading(false);
      }
  }

  useEffect(() => {
      generateQRForAuth();
  }, []);

  return (
      <>

          {showVerifyPopup &&
              <TwoFactorAuthVerify
                  show={showVerifyPopup}
                  handleClose={(isMSG) => {
                      if(isMSG) {
                          setSuccess('Please be advised that the enabling of Two-Factor Authentication has been skipped, and proceeding without it may compromise security.');
                          handleClose();
                      }
                      setShowVerifyPopup(false)
                  }}
                  handleSuccess={(e) => {
                      handleSuccess(e);
                  }}
                  setAuthOTP={setAuthOTP}
                  authOTP={authOTP}
              />
          }

          <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="gst_googleauthentication_module modal"
          >

              {loading && <LoadingIndicator />}


              <Modal.Header closeButton className="gst_googleauthentication_header">
                  <h4 className="modal-title">Two-Factor Authentication (2FA)</h4>
              </Modal.Header>

              <Modal.Body className='pt-2 pb-2 px-3'>
                  <div className="row">
                      <div className="col-md-12">
                          <h5 className="gst_googleauthentication_titlefs">
                              Follow below steps to set up Two-Factor Authentication (2FA):
                          </h5>
                          <div className="gst_googleauthentication_listbox">
                              <ol className="gst_googleauthentication_ollistbox">
                                  <li>                                       
                                      Download Google Authenticator app and open it.
                                  </li>
                                  <li>
                                      Scan the below QR on the authenticator app.
                                  </li>
                                  <li>
                                      Once you get the OTP code on your authenticator app, click Next below and enter the code.
                                  </li>

                              </ol>
                          </div>
                      </div>
                      <div className="col-xl-12 text-center">
                          <div className="gst_googleauthentication_sacncode">
                              {
                                  qrCodeUrl &&
                                  <QRCode
                                      value={qrCodeUrl}
                                      style={{ height: "40%", width: "40%" }}
                                  />
                              }


                          </div>
                      </div>
                      <div className="col-xl-12 text-center pt-3">
                          <button className="btn btn-default gst_factorauth_scanbar_nextbtn" onClick={() => {setAuthOTP(''); setShowVerifyPopup(true);}}>
                              Next
                          </button>
                      </div>
                  </div>
              </Modal.Body>

          </Modal>
      </>
  )
}

export default EnableTwoFactorAuthentication